import React from 'react';
import CV from '../../assests/CV.pdf';

const CTA = () => {
  return (
    <div className='cta'>
        <a href={CV} download="Abdullah Sattar SE Resume.pdf" className='btn'> Download CV</a>
        <a href='#contact' className='btn btn-primary'> Let's Talk</a>
    </div>
  )
}

export default CTA;
