import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {SiLeetcode} from 'react-icons/si'
import {BsInstagram} from 'react-icons/bs'
import {BsMedium} from 'react-icons/bs'


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/abdullahsattar4/' target='_blanl'><BsLinkedin/></a>
        <a href='https://github.com/abdullahsattaar' target='_blanl'><FaGithub/></a>
        <a href='https://leetcode.com/u/abdullahsattar' target='_blanl'><SiLeetcode/></a>
        <a href='https://www.instagram.com/abdullahsattaar/' target='_blanl'><BsInstagram/></a>
        <a href='https://medium.com/@abdullahsattaar' target='_blanl'><BsMedium/></a>
        
    </div>
  )
}

export default HeaderSocials